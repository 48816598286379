/*
 * @Description:
 * @Author: Devin
 * @Date: 2025-03-03 12:14:11
 */
import { create } from "zustand";

// Create a Zustand store for managing conversations
export const useConversations = create((set) => ({
  refreshConversationsHint: 1,
  conversations: [],

  setConversations: (newConversations) => {
    console.log(newConversations, "conversations");
    set({ conversations: newConversations });
  },

  setRefreshConversationsHint: () =>
    set((state) => ({
      refreshConversationsHint: state.refreshConversationsHint + 1,
    })),
}));
