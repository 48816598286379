/*
 * @Description:
 * @Author: Devin
 * @Date: 2025-02-20 15:30:07
 */
import { useEffect } from "react";
import { Dialog } from "../ui/Dialog.tsx";
import DialogTemplate from "../ui/DialogTemplate";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "~/hooks/AuthContext";
import { useConversations, useConversation } from "~/store";
import { chatAPI } from "~/data-provider";

const ClearConvos = ({ open, onOpenChange }) => {
  const { newConversation } = useConversation();

  const { setRefreshConversationsHint, conversations } = useConversations();

  const { user } = useAuthContext();

  const navigate = useNavigate();
  const clickHandler = async () => {
    console.log("Clearing conversations...");
    for (const conversation of conversations) {
      const data = await chatAPI.deleteConversationById(
        conversation.id,
        user?.id
      );
      console.log(data, "data");
    }

    newConversation();
    setRefreshConversationsHint();
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTemplate
        title="清空聊天记录"
        description="Are you sure you want to clear all conversations? This is irreversible."
        selection={{
          selectHandler: clickHandler,
          selectClasses:
            "bg-red-600 hover:bg-red-700 dark:hover:bg-red-800 text-white",
          selectText: "Clear",
        }}
      />
    </Dialog>
  );
};

export default ClearConvos;
