/*
 * @Description:
 * @Author: Devin
 * @Date: 2025-02-20 15:30:07
 */
import { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import ChatList from "../components/aiTools";
import { chatAPI } from "~/data-provider";
import { useConversation } from "~/store";
import { useAuthContext } from "~/hooks/AuthContext";

export default function Chat() {
  const { currentConversation, switchToConversation, setMessages } =
    useConversation();

  const { user } = useAuthContext();

  const [messageList, setMessageList] = useState([]);

  const getMessages = async (conversationId, userId) => {
    const { data } = await chatAPI.getMessagesByConvoId(conversationId, userId);
    console.log(data, "getMessagesByConvoId");
    setMessageList(data);
    setMessages(data);
  };

  useEffect(() => {
    document.title =
      currentConversation?.name ||
      currentConversation?.title ||
      import.meta.env.VITE_APP_TITLE ||
      "DeepLink";
  }, [currentConversation?.id]);

  useEffect(() => {
    if (currentConversation?.id && user?.id) {
      getMessages(currentConversation?.id, user?.id);
    }
  }, [currentConversation?.id, user?.id]);

  return (
    <>
      <ChatList
        appChatListData={messageList ?? []}
        currentConversationId={currentConversation?.id}
      />
    </>
  );
}
