import { Fragment, useState } from "react";
// import ProgressTooltip from "./progress-tooltip";
import {
  PortalToFollowElem,
  PortalToFollowElemContent,
  PortalToFollowElemTrigger,
} from "./portal-to-follow-elem";
import FileIcon from "./FileIcon";

const Popup = ({ data, showHitInfo = false }) => {
  const [open, setOpen] = useState(false);
  const fileType =
    data.dataSourceType !== "notion"
      ? /\.([^.]*)$/g.exec(data.documentName)?.[1] || ""
      : "notion";

  return (
    <PortalToFollowElem
      open={open}
      onOpenChange={setOpen}
      placement="top-start"
      offset={{
        mainAxis: 8,
        crossAxis: -2,
      }}
    >
      <PortalToFollowElemTrigger onClick={() => setOpen((v) => !v)}>
        <div className="flex items-center px-2 max-w-[240px] h-7 bg-white dark:bg-[#2f2f2f] rounded-lg ">
          <FileIcon type={fileType} className="shrink-0 mr-1 w-4 h-4" />
          <div className="text-xs text-gray-600 dark:text-white truncate">
            {data.documentName}
          </div>
        </div>
      </PortalToFollowElemTrigger>
      <PortalToFollowElemContent style={{ zIndex: 1000 }}>
        <div className="max-w-[360px] bg-gray-50 dark:bg-[#2f2f2f] rounded-xl shadow-lg">
          <div className="px-4 pt-3 pb-2">
            <div className="flex items-center h-[18px]">
              <FileIcon type={fileType} className="shrink-0 mr-1 w-4 h-4" />
              <div className="text-xs font-medium text-gray-600  dark:text-white truncate">
                {data.documentName}
              </div>
            </div>
          </div>
          <div className="px-4 py-0.5 max-h-[450px] bg-white dark:bg-[#2f2f2f] rounded-lg overflow-y-auto">
            <div className="w-full">
              {data.sources.map((source, index) => (
                <Fragment key={index}>
                  <div className="group py-3">
                    <div className="flex items-center justify-between mb-2 ">
                      <div className="flex items-center px-1.5 h-5 border border-gray-200 dark:border-gray-500  rounded-md">
                        {/* <Hash02 className="mr-0.5 w-3 h-3 text-gray-400" /> */}
                        <div className="text-[11px] font-medium text-gray-500 dark:text-white ">
                          {source.segment_position || index + 1}
                        </div>
                      </div>
                    </div>
                    <div className="text-[13px] text-gray-800 dark:text-white break-words">
                      {source.content}
                    </div>
                  </div>
                  {index !== data.sources.length - 1 && (
                    <div className="my-1 h-[1px] bg-black/5" />
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </PortalToFollowElemContent>
    </PortalToFollowElem>
  );
};

export default Popup;
