import React, { useState, useEffect, useCallback } from "react";
import { Box, Container } from "@mui/material";
import { getTemQrcode, getIsLogin } from "./requests";
import { useUserStore } from "~/store";
import "./index.scss";

import { qrcodeImg } from "qiao-qrcode";

export const container = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const box = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};

const LoginWechat = (props) => {
  const { user, updateUser, setIsFirstLogin, setIsLogin, updateAccessToken } =
    useUserStore();

  const [currentSessionId, setCurrentSessionId] = useState("");
  const [qrIsLoading, setQrIsLoading] = useState(false);

  useEffect(() => {
    getQr();
  }, []);

  useEffect(() => {
    if (!currentSessionId) return;

    let fromOrigin = sessionStorage.getItem("From") || "";

    const interval = setInterval(() => {
      getIsLogin(currentSessionId, fromOrigin, {
        onCallBack(data) {
          console.log(data, data.data.access_token, "data2");
          if (data.data.access_token) {
            clearInterval(interval);
            setIsLogin(true);
            updateAccessToken(data.data.access_token);
            props?.onClose();
          }
          console.log("data: ", data);
        },
        interval: interval,
      });
    }, 2000);

    return () => clearInterval(interval);
  }, [currentSessionId]);

  const getQr = async () => {
    try {
      await getTemQrcode({
        onCallBack(data) {
          console.log("data: ", data);
          new Promise((resolve) => {
            qrcodeImg("login-qrcode", data.data.url);
            resolve("");
          }).then(() => {
            const divElement = document.getElementById("login-qrcode");
            if (divElement) {
              const images = divElement.getElementsByTagName("img");
              if (images.length > 1) {
                divElement.removeChild(images[0]);
              }
            }
          });

          setCurrentSessionId(data.session_id);
        },
        setQrIsLoading,
      });
    } catch (err) {
      console.log("err: ", err);
    }
  };

  return (
    <Container maxWidth={false} sx={container}>
      <Box sx={box}>
        <div className="qrcode-div">
          <div className="qrcood-title">
            <div className="text">{"微信关注公众号，登录使用"}</div>
          </div>
          <div className="qrcode">
            <div id="login-qrcode" />
            {qrIsLoading && (
              <div className="qrcode-mask">
                <div className="spinner"></div>
              </div>
            )}
          </div>
        </div>
      </Box>
    </Container>
  );
};

export default React.memo(LoginWechat);
