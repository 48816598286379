import { toast } from "react-toastify";

export async function getIsLogin(session_id, from, options) {
  try {
    const res = await fetch(`/api/user/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ sessionID: session_id, From: from }),
    });

    if (res.ok) {
      const data = await res.json();
      options?.onCallBack(data);
    } else {
      const data = await res.json();
      throw Error(data.message);
    }
  } catch (err) {
    clearInterval(options?.interval);
    toast.error(err.message || "登录失败", {
      position: "top-right",
    });
  }
}

export async function getTemQrcode(options) {
  try {
    options.setQrIsLoading(true);
    const res = await fetch(`/api/wechat/qrcode/temp`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (res.ok) {
      const data = await res.json();
      options.onCallBack(data);
      options.setQrIsLoading(false);
    } else {
      const data = await res.json();
      options.setQrIsLoading(false);
      throw Error(data.message);
    }
  } catch (err) {
    options.setQrIsLoading(false);
    toast.error(err.message || "获取登录二维码失败", {
      position: "top-right",
    });
  }
}
