import React from "react";
import styles from "./ui-lib.module.scss";
import LoadingIcon from "./three-dots.svg";
import CloseIcon from "./close.svg";
import { createRoot } from "react-dom/client";

export function Popover(props) {
  return (
    <div className={styles.popover}>
      {props.children}
      {props.open && (
        <div className={styles["popover-content"]}>
          <div className={styles["popover-mask"]} onClick={props.onClose}></div>
          {props.content}
        </div>
      )}
    </div>
  );
}

export function Card(props) {
  return (
    <div className={styles.card + " " + (props.className || "")}>
      {props.children}
    </div>
  );
}

export function ListItem(props) {
  if (React.Children.count(props.children) > 2) {
    throw Error("Only Support Two Children");
  }

  return <div className={styles["list-item"]}>{props.children}</div>;
}

export function List(props) {
  return <div className={styles.list}>{props.children}</div>;
}

export function Loading() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={LoadingIcon} alt="" />
    </div>
  );
}

export function Modal({
  title,
  children,
  onClose,
  actions,
  modalStyle,
  style,
}) {
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center ${modalStyle}`}
    >
      <div
        className={
          "bg-white dark:bg-gray-800 rounded-lg shadow-lg animate-slide-in max-w-xl"
        }
      >
        {/* Modal Header */}
        <div className="flex items-center justify-between border-b border-gray-200 dark:border-gray-700 p-5">
          <div className="text-lg font-bold text-gray-900 dark:text-gray-100">
            {title}
          </div>
          <button onClick={onClose} className="p-2 hover:brightness-110">
            <img src={CloseIcon} alt="Close" className="w-5 h-5" />
          </button>
        </div>

        {/* Modal Content */}
        <div
          className="max-h-[40vh] overflow-auto p-5 text-gray-800 dark:text-gray-300"
          style={style}
        >
          {React.cloneElement(children, { onClose })}
        </div>

        {/* Modal Footer */}
        {actions && (
          <div className="flex justify-end border-t border-gray-200 dark:border-gray-700 p-5">
            <div className="flex space-x-4">
              {actions.map((action, i) => (
                <div key={i}>{action}</div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export function showModal(props) {
  const div = document.createElement("div");
  div.className = "fixed inset-0 flex items-center justify-center z-[9999]";
  document.body.appendChild(div);

  const root = createRoot(div);
  const closeModal = () => {
    props.onClose?.();
    root.unmount();
    div.remove();
  };

  div.onclick = (e) => {
    if (e.target === div) {
      closeModal();
    }
  };

  root.render(<Modal {...props} onClose={closeModal} />);
}

export function Toast(props) {
  return (
    <div className={styles["toast-container"]}>
      <div className={styles["toast-content"]}>{props.content}</div>
    </div>
  );
}

export function showToast(content, delay = 3000) {
  const div = document.createElement("div");
  div.className = styles.show;
  document.body.appendChild(div);

  const root = createRoot(div);
  const close = () => {
    div.classList.add(styles.hide);

    setTimeout(() => {
      root.unmount();
      div.remove();
    }, 300);
  };

  setTimeout(() => {
    close();
  }, delay);

  root.render(<Toast content={content} />);
}
