import React, { useMemo, createContext, useContext, forwardRef } from "react";
import {
  FloatingPortal,
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  useRole,
} from "@floating-ui/react";

export function usePortalToFollowElem({
  placement = "bottom",
  open,
  offset: offsetValue = 0,
  onOpenChange: setControlledOpen,
} = {}) {
  const setOpen = setControlledOpen;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(offsetValue),
      flip({
        crossAxis: placement.includes("-"),
        fallbackAxisSideDirection: "start",
        padding: 5,
      }),
      shift({ padding: 5 }),
    ],
  });

  const context = data.context;

  const hover = useHover(context, {
    move: false,
    enabled: open == null,
  });
  const focus = useFocus(context, {
    enabled: open == null,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });

  const interactions = useInteractions([hover, focus, dismiss, role]);

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data]
  );
}

const PortalToFollowElemContext = createContext(null);

export function usePortalToFollowElemContext() {
  const context = useContext(PortalToFollowElemContext);

  if (context == null)
    throw new Error(
      "PortalToFollowElem components must be wrapped in <PortalToFollowElem />"
    );

  return context;
}

export function PortalToFollowElem({ children, ...options }) {
  const tooltip = usePortalToFollowElem(options);
  return (
    <PortalToFollowElemContext.Provider value={tooltip}>
      {children}
    </PortalToFollowElemContext.Provider>
  );
}

export const PortalToFollowElemTrigger = forwardRef(
  ({ children, asChild = false, ...props }, propRef) => {
    const context = usePortalToFollowElemContext();
    const childrenRef = children.ref;
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

    if (asChild && React.isValidElement(children)) {
      return React.cloneElement(
        children,
        context.getReferenceProps({
          ref,
          ...props,
          ...children.props,
          "data-state": context.open ? "open" : "closed",
        })
      );
    }

    return (
      <div
        ref={ref}
        className={`inline-block ${props.className}`}
        data-state={context.open ? "open" : "closed"}
        {...context.getReferenceProps(props)}
      >
        {children}
      </div>
    );
  }
);
PortalToFollowElemTrigger.displayName = "PortalToFollowElemTrigger";

export const PortalToFollowElemContent = forwardRef(
  ({ style, ...props }, propRef) => {
    const context = usePortalToFollowElemContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    if (!context.open) return null;

    const body = document.body;

    return (
      <FloatingPortal root={body}>
        <div
          ref={ref}
          style={{
            ...context.floatingStyles,
            ...style,
          }}
          {...context.getFloatingProps(props)}
        />
      </FloatingPortal>
    );
  }
);

PortalToFollowElemContent.displayName = "PortalToFollowElemContent";
