import {
  useState,
  useCallback,
  useEffect,
  useMemo,
  ReactNode,
  createContext,
  useContext,
} from "react";
import { TUser, setXTokenHeader, TLoginUser, userAPI } from "~/data-provider";
import { useNavigate, useLocation } from "react-router-dom";
import { useUserStore } from "~/store";
import { toast } from "react-toastify";

export type TAuthContext = {
  user: TUser | undefined;
  token: string | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
  error: string | undefined;
  login: (data: TLoginUser) => void;
  logout: () => void;
};

export type TUserContext = {
  user?: TUser | undefined;
  token: string | undefined;
  isAuthenticated: boolean;
  redirect?: string;
};

const AuthContext = createContext<TAuthContext | undefined>(undefined);

const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [token, setToken] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const navigate = useNavigate();

  const {
    user,
    updateUser,
    isLoginModal,
    setIsLoginModal,
    isLogin,
    accessToken,
    clearIsLogin,
    updateAccessToken,
    setIsLogin,
  } = useUserStore();

  const getUserInfo = async () => {
    const response = await userAPI.getMe();
    if (response.data) {
      console.log(response.data, "response.data");
      updateUser(response.data);
    }
  };

  useEffect(() => {
    setToken(accessToken);
    setIsAuthenticated(isLogin);
    getUserInfo();
  }, [isLogin]);

  const login = async (payload: TLoginUser) => {
    const data = await userAPI.login(payload);
    console.log(data, "data");
    if (data.success && data.data) {
      const { access_token } = data.data;
      setToken(access_token);
      setXTokenHeader(access_token);
      setIsAuthenticated(true);
      setIsLogin(true);
      updateAccessToken(access_token);
    } else {
      setError(data.error);
    }
  };

  const logout = async () => {
    const data = await userAPI.logout();
    if (data.success) {
      setToken("");
      setIsAuthenticated(false);
      updateUser({});
      clearIsLogin();
      toast.success("Logout successfully");
    } else {
      setError(data.error);
    }
  };

  const silentRefresh = useCallback(async () => {
    console.log(user?.id, "user?.id");
    if (!user?.id) return;
    const { data } = await userAPI.refreshToken();
    if (data) {
      const { access_token: token } = data;
      setToken(token);
      setXTokenHeader(token);
      setIsAuthenticated(true);
    }
  }, [user]);

  // Make the provider update only when it should
  const memoedValue = useMemo(
    () => ({
      user,
      token,
      isAuthenticated,
      isLoading,
      error,
      login,
      logout,
      silentRefresh,
      setIsLoginModal,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, isLoading, error, isAuthenticated, token, silentRefresh]
  );

  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  );
};

const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuthContext should be used inside AuthProvider");
  }

  return context;
};

export { AuthContextProvider, useAuthContext };
