/*
 * @Description:
 * @Author: Devin
 * @Date: 2025-03-03 12:14:11
 */
import { create } from "zustand";

export const useConversation = create((set) => ({
  currentConversation: null,
  messages: [],

  switchToConversation: (conversation) => {
    set({
      currentConversation: conversation,
    });
  },
  setMessages: (messages) => {
    set({
      messages: messages,
    });
  },

  newConversation: () => {
    set({
      currentConversation: {},
      messages: [],
    });
  },
}));
