/*
 * @Description:
 * @Author: Devin
 * @Date: 2025-03-03 15:37:05
 */
import ArIcon from "~/components/arIcon";

const FileIcon = ({ type, className }) => {
  switch (type) {
    case "csv":
      return <ArIcon className={className} name={"Csv"} />;
    case "doc":
      return <ArIcon className={className} name={"Doc"} />;
    case "docx":
      return <ArIcon className={className} name={"Docx"} />;
    case "htm":
    case "html":
      return <ArIcon className={className} name={"Html"} />;
    case "json":
      return <ArIcon className={className} name={"Json"} />;
    case "md":
    case "markdown":
      return <ArIcon className={className} name={"Md"} />;
    case "pdf":
      return <ArIcon className={className} name={"Pdf"} />;
    case "txt":
      return <ArIcon className={className} name={"Txt"} />;
    case "xls":
    case "xlsx":
      return <ArIcon className={className} name={"Xlsx"} />;
    default:
      return <ArIcon className={className} name={"Unknown"} />;
  }
};

export default FileIcon;
