/*
 * @Description:
 * @Author: Devin
 * @Date: 2025-03-24 15:35:10
 */
import axios from "axios";
import * as types from "./types";
import request from "./request";

// 用户API
export const userAPI = {
  // 获取当前用户信息
  getMe: async (): Promise<types.ApiResponse<types.User>> => {
    try {
      const response = await request.get("/api/user/getme");
      return response as types.ApiResponse<types.User>;
    } catch (error) {
      return {
        success: false,
        error: "获取用户信息失败",
      };
    }
  },
  refreshToken: async (): Promise<types.ApiResponse<types.RefreshData>> => {
    try {
      const response: types.ApiResponse<types.RefreshData> =
        await request.get("/api/user/refresh");
      if (response.success) {
        return response;
      } else {
        return {
          success: false,
          error: response.error || "获取应用信息失败",
        };
      }
    } catch (error: any) {
      return error.response;
    }
  },
  // 用户登录
  login: async (credentials: {
    identifier: string;
    password: string;
  }): Promise<types.ApiResponse<{ access_token: string }>> => {
    try {
      // 通过正则表达式判断是邮箱还是用户名
      const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(credentials.identifier);

      // 发送请求时，动态选择 email 或 username
      const payload = isEmail
        ? { email: credentials.identifier, password: credentials.password }
        : { name: credentials.identifier, password: credentials.password };

      const response = await request.post("/api/user/login", payload);

      return response;
    } catch (error: any) {
      return {
        success: false,
        error: error.response ? error.response.data.error : "登录失败",
      };
    }
  },
  // 退出登录
  logout: async (): Promise<types.ApiResponse<any>> => {
    try {
      const response: types.ApiResponse<any> =
        await request.get("/api/user/logout");
      if (response.success) {
        return response;
      } else {
        return {
          success: false,
          error: response.error || "退出登录失败",
        };
      }
    } catch (error) {
      return {
        success: false,
        error: "退出登录失败",
      };
    }
  },
  // 用户注册
  register: async (
    userData: Partial<types.User>
  ): Promise<types.ApiResponse<types.User>> => {
    try {
      const response = await request.post("/api/user/register", userData);
      return response.data;
    } catch (error: any) {
      console.log(error, "error");
      return {
        success: false,
        error: error.response ? error.response.data.error : "用户注册失败",
      };
    }
  },
};

// APP API
export const appAPI = {
  // 获取当前用户信息
  getShowApp: async (): Promise<types.ApiResponse<types.APP[]>> => {
    try {
      const response: types.ApiResponse<types.APP[]> =
        await request.get("/api/app/show");
      if (response.success) {
        return response;
      } else {
        return {
          success: false,
          error: response.error || "获取应用信息失败",
        };
      }
    } catch (error: any) {
      return error.response;
    }
  },
  getChatApiKey: async (
    id: string
  ): Promise<types.ApiResponse<types.ApiKey>> => {
    try {
      const response: types.ApiResponse<types.ApiKey> = await request.get(
        `/api/app/api_key/${id}`
      );
      if (response.success) {
        return response;
      } else {
        return {
          success: false,
          error: response.error || "获取APIKey失败",
        };
      }
    } catch (error: any) {
      return error.response;
    }
  },
};

// Chat API
export const chatAPI = {
  getMessagesByConvoId: async (
    id: string,
    userId: string
  ): Promise<types.ApiResponse<types.ConversationMessage[]>> => {
    try {
      const response: types.ApiResponse<types.ConversationMessage[]> =
        await request.get(
          `/api/v1/messages?user=${userId}&conversation_id=${id}`
        );
      if (!!response.data) {
        return response;
      } else {
        return {
          success: false,
          error: response.error || "获取消息失败",
        };
      }
    } catch (error: any) {
      return error.response;
    }
  },
  getConversations: async (
    userId: string,
    limit: string = "20"
  ): Promise<types.ApiResponse<types.Conversation[]>> => {
    try {
      const response: types.ApiResponse<types.Conversation[]> =
        await request.get(
          `/api/v1/conversations?user=${userId}&&last_id=&limit=${limit}`
        );
      if (!!response.data) {
        return response;
      } else {
        return {
          success: false,
          error: response.error || "获取聊天列表失败",
        };
      }
    } catch (error: any) {
      return error.response;
    }
  },
  stopChat: async (task_id: string, userId: string): Promise<any> => {
    try {
      const response: any = await request.post(
        `/api/v1/chat-messages/${task_id}/stop`,
        {
          user: userId,
        }
      );
      if (!!response.data) {
        return response;
      } else {
        return {
          success: false,
          error: response.error || "获取聊天列表失败",
        };
      }
    } catch (error: any) {
      return error.response;
    }
  },
  deleteConversationById: async (
    conversation_id: string,
    userId: string
  ): Promise<types.ApiResponse<any>> => {
    try {
      const response: types.ApiResponse<any> = await request.delete(
        `/api/v1/conversations/${conversation_id}`,
        {
          user: userId,
        }
      );
      if (!!response.data) {
        return response;
      } else {
        return {
          success: false,
          error: response.error || "删除指定聊天记录失败",
        };
      }
    } catch (error: any) {
      return error.response;
    }
  },
};

// async function withTokenRefresh(apiCall) {
//   try {
//     const response = (await refreshToken()) as any;
//     // Check if the response status is not 200
//     if (!response.success) {
//       throw new Error("Token refresh failed: Unauthorized");
//     }

//     // If successful, proceed with the API call
//     return await apiCall();
//   } catch (error) {
//     // Handle the error (e.g., log it, display a message)
//     console.error("Token refresh failed:", error);
//     throw new Error("Token refresh failed");
//   }
// }
