/*
 * @Description:
 * @Author: Devin
 * @Date: 2025-03-03 12:14:11
 */
import axios, { AxiosRequestConfig } from "axios";

const AUTH_HEADER_APP = {
  Authorization: "Bearer app-9DAsSstoxmjlXN0GZOns3tSY",
};
const AUTH_HEADER = {};

function getAuthHeader(url: string) {
  return url.startsWith("/api/v1") ? AUTH_HEADER_APP : AUTH_HEADER;
}

async function _get<T>(url: string, options?: AxiosRequestConfig): Promise<T> {
  const response = await axios.get(url, {
    ...options,
    // headers: { ...getAuthHeader(url), ...options?.headers },
  });
  return response.data;
}

async function _post(url: string, data?: any) {
  const response = await axios.post(url, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });
  return response.data;
}

async function _postMultiPart(
  url: string,
  formData: FormData,
  options?: AxiosRequestConfig
) {
  const response = await axios.post(url, formData, {
    ...options,
    headers: {
      "Content-Type": "multipart/form-data",
      ...options?.headers,
    },
  });
  return response.data;
}

async function _put(url: string, data?: any) {
  const response = await axios.put(url, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });
  return response.data;
}

async function _delete<T>(url: string, data?: any): Promise<T> {
  console.log(data, "dat");
  const response = await axios.delete(url, {
    data: data,
    headers: { "Content-Type": "application/json" },
  });
  return response.data;
}

async function _deleteWithOptions<T>(
  url: string,
  options?: AxiosRequestConfig
): Promise<T> {
  const response = await axios.delete(url, {
    ...options,
    headers: { ...options?.headers },
  });
  return response.data;
}

async function _patch(url: string, data?: any) {
  const response = await axios.patch(url, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });
  return response.data;
}

export default {
  get: _get,
  post: _post,
  postMultiPart: _postMultiPart,
  put: _put,
  delete: _delete,
  deleteWithOptions: _deleteWithOptions,
  patch: _patch,
};
