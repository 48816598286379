/*
 * @Description:
 * @Author: Devin
 * @Date: 2025-03-07 10:27:39
 */
import { create } from "zustand";
import { persist } from "zustand/middleware";

export const USER_KEY = "user-info";

export const useUserStore = create(
  persist(
    (set, get) => ({
      user: {},
      isFirstLogin: false,
      isLoginModal: false,
      isLogin: false,
      tip: "",
      accessToken: "",
      updateAccessToken: (accessToken) => set(() => ({ accessToken })),
      updateUser: (user) => set(() => ({ user })),
      setIsFirstLogin: (isFirstLogin) => set(() => ({ isFirstLogin })),
      setIsLoginModal: (isLoginModal) => set(() => ({ isLoginModal })),
      setIsLogin: (isLogin) => {
        set(() => ({ isLogin }));
        if (!isLogin) {
          // get().setIsLoginModal(true);
        }
      },
      clearIsLogin: () =>
        set(() => ({
          isLogin: false,
          isLoginModal: false,
          isFirstLogin: false,
          accessToken: "",
          user: {},
        })),
      updateTip: (tip) => set(() => ({ tip })),
    }),
    {
      name: USER_KEY,
      version: 1,
    }
  )
);
