/*
 * @Description:
 * @Author: Devin
 * @Date: 2025-02-20 09:57:11
 */
import { forwardRef, useEffect } from "react";
import { useAuthContext } from "~/hooks/AuthContext";
import { useNavigate } from "react-router-dom";

import LogoutIcon from "../svg/LogoutIcon";

const Logout = forwardRef(() => {
  const { user, logout, isAuthenticated, setIsLoginModal } = useAuthContext();
  const navigate = useNavigate();
  const handleLogout = async () => {
    if (isAuthenticated) {
      await logout();
    } else {
      // setIsLoginModal(true);
      navigate("/login");
    }

    // window.location.reload();
  };

  useEffect(() => {
    console.log(isAuthenticated, "isAuthenticated");
  }, [isAuthenticated]);

  return (
    <button
      className="rounded-md flex w-full cursor-pointer items-center gap-3 px-3 py-3 text-sm transition-colors duration-200  text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
      onClick={handleLogout}
    >
      <LogoutIcon />
      {/* {user?.username || 'USER'} */}
      {isAuthenticated ? "退出登录" : "登录"}
      {/* <small>Log out</small> */}
    </button>
  );
});

export default Logout;
