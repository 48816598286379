/*
 * @Description:
 * @Author: Devin
 * @Date: 2025-03-04 12:04:36
 */
import { create } from "zustand";
import { persist } from "zustand/middleware";

export const APP_KEY = "useAppList";

export const useAppList = create()(
  persist(
    (set, get) => ({
      appList: [],
      appAccessTokenList: {},
      currentApp: {},
      currentAppDefault: {},
      currentAppTokenRefresh: 1,
      updateAppList: (appList) => set({ appList: appList }),
      updateAppAccessTokenList: (key, accessToken) => {
        set({
          appAccessTokenList: {
            ...get().appAccessTokenList,
            [key]: accessToken,
          },
        });
      },
      updateCurrentApp: (app) => set({ currentApp: app }),
      updateCurrentAppDefault: (app) => set({ currentAppDefault: app }),
      updateCurrentAppTokenRefresh: () =>
        set({ currentAppTokenRefresh: get().currentAppTokenRefresh + 1 }),
    }),
    {
      name: APP_KEY,
      version: 1,
    }
  )
);
