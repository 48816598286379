/*
 * @Description:
 * @Author: Devin
 * @Date: 2025-02-21 10:54:09
 */
import React, { useMemo, useState, useEffect, useCallback } from "react";
import Chat from "./chat";
import { useChat } from "./hook";
import { chatAPI } from "~/data-provider";
import { useAuthContext } from "~/hooks/AuthContext";
import { useAppList } from "~/store";

const ChatList = ({ appChatListData, currentConversationId }) => {
  const { user } = useAuthContext();

  const {
    currentApp,
    currentAppDefault,
    updateCurrentApp,
    updateCurrentAppDefault,
  } = useAppList();

  const stopChat = useCallback(
    async (taskId) => {
      console.log(`Stop chat with taskId: ${taskId}`);
      const data = await chatAPI.stopChat(taskId, user.id);
      console.log(data, "stopChat");
    },
    [user]
  );

  const appPrevChatList = useMemo(() => {
    const data = appChatListData;

    const chatList = [];

    if (currentConversationId && data.length) {
      data.forEach((item) => {
        chatList.push({
          id: `question-${item.id}`,
          content: item.query,
          isAnswer: false,
          message_files:
            item.message_files?.filter((file) => file.belongs_to === "user") ||
            [],
        });
        chatList.push({
          id: item.id,
          content: item.answer,
          isAnswer: true,
          message_files:
            item.message_files?.filter(
              (file) => file.belongs_to === "assistant"
            ) || [],
          citation: item?.retriever_resources ?? [],
        });
      });
    }
    return chatList;
  }, [appChatListData, currentConversationId]);

  const {
    chatList,
    setChatList,
    conversationId,
    isResponding,
    setIsResponding,
    handleSend,
    handleRestart,
    handleStop,
    currentAppToken,
  } = useChat(
    appPrevChatList,
    stopChat,
    currentConversationId,
    currentApp,
    currentAppDefault,
    updateCurrentApp,
    updateCurrentAppDefault
  );

  return (
    <div className="flex flex-col h-full">
      <Chat
        chatList={chatList}
        setChatList={setChatList}
        conversationId={conversationId}
        handleSend={handleSend}
        handleRestart={handleRestart}
        handleStop={handleStop}
        isResponding={isResponding}
        setIsResponding={setIsResponding}
        currentAppToken={currentAppToken}
        currentApp={currentApp}
      />
    </div>
  );
};

export default ChatList;
