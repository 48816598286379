/*
 * @Description:
 * @Author: Devin
 * @Date: 2025-03-03 12:14:11
 */
import axios from "axios";

export function setAcceptLanguageHeader(value: string): void {
  axios.defaults.headers.common["Accept-Language"] = value;
}

export function setTokenHeader(token: string) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

export function setXTokenHeader(token: string) {
  axios.defaults.headers.common["X-Authorization"] = "Bearer " + token;
}
