/*
 * @Description:
 * @Author: Devin
 * @Date: 2025-03-03 12:14:11
 */
import { createRoot } from "react-dom/client";
import App from "./App";
import "./style.css";
import "./mobile.css";
import { ApiErrorBoundaryProvider } from "./hooks/ApiErrorBoundaryContext";
import { ToastContainer } from "react-toastify";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <ApiErrorBoundaryProvider>
    <App />
    <ToastContainer />
  </ApiErrorBoundaryProvider>
);
