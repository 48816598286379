/*
 * @Description:
 * @Author: Devin
 * @Date: 2025-03-03 12:14:11
 */
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Nav from "../components/Nav";
import MobileNav from "../components/Nav/MobileNav";
import { setTokenHeader, appAPI } from "~/data-provider";
import { useAppList, useUserStore } from "~/store";

import LoginWechat from "~/components/loginWechat";

import { showModal } from "~/components/ui-lib";

import { useAuthContext } from "~/hooks/AuthContext";
export default function Root() {
  const [navVisible, setNavVisible] = useState(false);

  const { isAuthenticated, silentRefresh } = useAuthContext();

  const {
    appList,
    updateAppList,
    appAccessTokenList,
    updateAppAccessTokenList,
    currentApp,
    updateCurrentAppTokenRefresh,
    updateCurrentApp,
  } = useAppList();

  const getShowApp = async () => {
    const { data } = await appAPI.getShowApp();
    if (data && data?.length && data?.length > 0) {
      updateAppList(data);
    } else {
      updateAppList([]);
    }
  };

  useEffect(() => {
    if (!Array.isArray(appList)) {
      // appList 不是数组时执行的代码
      updateAppList([]);
    }
    getShowApp();
  }, [isAuthenticated]);

  useEffect(() => {
    if (appList && appList.length == 1) {
      updateCurrentApp(appList[0]);
    }
  }, [appList]);

  const getChatApiKeyHandler = async (id) => {
    if (!appList || appList.length < 1) return;
    let token = appAccessTokenList[id];
    if (!id) return;
    if (!token) {
      const { data } = await appAPI.getChatApiKey(id);
      token = data?.token;
    }
    updateAppAccessTokenList(id, token);
    setTokenHeader(token);
    updateCurrentAppTokenRefresh();
  };

  useEffect(() => {
    if (isAuthenticated) getChatApiKeyHandler(currentApp?.id);
  }, [appList, isAuthenticated, currentApp]);

  const { updateUser, isLoginModal, setIsLoginModal, isLogin } = useUserStore();

  const showModalHandler = () => {
    showModal({
      title: "登录",
      children: <LoginWechat></LoginWechat>,
      style: {
        maxHeight: "80vh",
        // width: "50vw",
        // backgroundColor: "var(--second)",
        paddingBottom: "3vh",
      },
      modalStyle: {
        width: "auto",
      },
    });
    setTimeout(() => {
      setIsLoginModal(false);
    }, 2000);
  };

  useEffect(() => {
    if (!isLoginModal) return;
    showModalHandler();
  }, [isLoginModal]);

  useEffect(() => {
    console.log(isLogin, "isLogin");
    if (isLogin) silentRefresh();
  }, [isLogin]);

  return (
    <>
      <div className="flex h-screen">
        <Nav navVisible={navVisible} setNavVisible={setNavVisible} />
        <div className="flex h-full w-full flex-1 flex-col bg-gray-50">
          <div className="transition-width relative flex h-full w-full flex-1 flex-col items-stretch overflow-hidden bg-white pt-10 dark:bg-gray-900 md:pt-0">
            <MobileNav setNavVisible={setNavVisible} />
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
